import { extendTheme } from '@chakra-ui/react';

import { styles as navigationStyles } from '~/components/navigation';
import { styles as richTextStyles } from '~/components/rich-text';

export default extendTheme({
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  colors: {
    brand: {
      pictonBlue: '#36B3E0',
      chelseaGem: '#944F06',
    },
  },
  textStyles: {
    heroTitle: {
      fontSize: ['3rem', '5rem'],
      fontWeight: '200',
      lineHeight: '110%',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    heroTagline: {
      fontSize: ['1rem', '1.5rem'],
      fontWeight: '200',
      lineHeight: '110%',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    sectionSubtitle: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      textTransform: 'uppercase',
      fontWeight: '200',
    },
    body: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    caption: {
      fontSize: ['0.75rem'],
      lineHeight: '1.5rem',
      fontStyle: 'italic',
      textAlign: 'center',
    },
  },
  components: {
    ...navigationStyles,
    ...richTextStyles,
  },
});
