import { defineStyleConfig } from '@chakra-ui/react';

export type NavigationLinkVariants = 'active';

export const NavigationLink = defineStyleConfig({
  baseStyle: {
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    _hover: {
      color: 'brand.pictonBlue',
    },
  },
  variants: {
    active: {
      color: 'brand.pictonBlue',
    },
  },
});
