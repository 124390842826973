import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { SharedRichTextProps } from './shared-rich-text-props.type';

export const RichTextEmbeddedAsset = ({ node }: SharedRichTextProps) => {
  const { gatsbyImageData, description } = node.data.target;
  const image = getImage(gatsbyImageData);

  if (!image) {
    return <></>;
  }

  return <GatsbyImage image={image} alt={description} />;
};
