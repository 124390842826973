import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPhone,
  faLocationDot,
  faLink,
  faBars,
  faPlay,
  faStop,
  faPause,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

export type IconProps = Omit<FontAwesomeIconProps, 'icon'>;
export type IconComponent = (props: IconProps) => JSX.Element;

export const Email: IconComponent = (props) => (
  <FontAwesomeIcon icon={faEnvelope} {...props} />
);

export const Phone: IconComponent = (props) => (
  <FontAwesomeIcon icon={faPhone} {...props} />
);

export const Address: IconComponent = (props) => (
  <FontAwesomeIcon icon={faLocationDot} {...props} />
);

export const GenericSocial: IconComponent = (props) => (
  <FontAwesomeIcon icon={faLink} {...props} />
);

export const Facebook: IconComponent = (props) => (
  <FontAwesomeIcon icon={faFacebookF} {...props} />
);

export const Instagram: IconComponent = (props) => (
  <FontAwesomeIcon icon={faInstagram} {...props} />
);

export const Linkedin: IconComponent = (props) => (
  <FontAwesomeIcon icon={faLinkedinIn} {...props} />
);

export const Menu: IconComponent = (props) => (
  <FontAwesomeIcon icon={faBars} {...props} />
);

export const Play: IconComponent = (props) => (
  <FontAwesomeIcon icon={faPlay} {...props} />
);

export const Stop: IconComponent = (props) => (
  <FontAwesomeIcon icon={faStop} {...props} />
);

export const Pause: IconComponent = (props) => (
  <FontAwesomeIcon icon={faPause} {...props} />
);
