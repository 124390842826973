import { PropsWithChildren } from 'react';
import { chakra } from '@chakra-ui/react';

export const ItemLayout = chakra('li', {
  baseStyle: {
    display: 'inline-block',
    px: 6,
    py: 4,
    _first: {
      pl: 0,
    },
    _last: {
      pr: 0,
    },
  },
});

export const ContainerLayout = ({ children }: PropsWithChildren) => (
  <chakra.nav role="navigation" aria-label="Main navigation">
    <chakra.ul display="flex" alignItems="center">
      {children}
    </chakra.ul>
  </chakra.nav>
);
