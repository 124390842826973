import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import { Menu as MenuIcon } from '~/components/icons';

export const ItemLayout = MenuItem;

export const ContainerLayout = ({ children }: PropsWithChildren) => (
  <Menu>
    <MenuButton
      as={IconButton}
      aria-label="Main navigation"
      icon={<MenuIcon />}
      variant="ghost"
    />
    <MenuList>{children}</MenuList>
  </Menu>
);
