import { ReactNode } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {
  Block,
  BLOCKS,
  Inline,
  INLINES,
  MARKS,
} from '@contentful/rich-text-types';
import {
  Box,
  Code,
  Divider,
  OrderedList,
  Text,
  UnorderedList,
  useStyleConfig,
} from '@chakra-ui/react';

import { RichTextDataWithAsset } from '~/utilities/rich-text.utility';
import { RichTextVariants } from './rich-text.styles';
import { SharedRichTextProps } from './shared-rich-text-props.type';
import { RichTextEmbeddedAsset } from './rich-text-embedded-asset.component';
import { RichTextHeading } from './rich-text-heading.component';
import { RichTextLink } from './rich-text-link.component';
import { RichTextParagraph } from './rich-text-paragraph.component';
import { RichTextQuote } from './rich-text-quote.component';

const toNodeRenderer =
  <P extends SharedRichTextProps>(
    Component: (props: P) => JSX.Element,
    defaultProps: Partial<P> = {}
  ) =>
  (node: Block | Inline, children: ReactNode) => {
    const props = { ...defaultProps, node, children } as P;
    return <Component {...props} />;
  };

const renderAppRichText = (content: RichTextDataWithAsset) =>
  renderRichText(content, {
    renderMark: {
      [MARKS.BOLD]: (text) => <Text as="b">{text}</Text>,
      [MARKS.ITALIC]: (text) => <Text as="i">{text}</Text>,
      [MARKS.UNDERLINE]: (text) => <Text as="u">{text}</Text>,
      [MARKS.CODE]: (text) => <Code>{text}</Code>,
      [MARKS.SUBSCRIPT]: (text) => <Text as="sub">{text}</Text>,
      [MARKS.SUPERSCRIPT]: (text) => <Text as="sup">{text}</Text>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: toNodeRenderer(RichTextEmbeddedAsset),
      [BLOCKS.HEADING_1]: toNodeRenderer(RichTextHeading, {
        as: 'h1',
        size: 'lg',
      }),
      [BLOCKS.HEADING_2]: toNodeRenderer(RichTextHeading, {
        as: 'h2',
        size: 'md',
      }),
      [BLOCKS.HEADING_3]: toNodeRenderer(RichTextHeading, {
        as: 'h3',
        size: 'sm',
      }),
      [BLOCKS.HEADING_4]: toNodeRenderer(RichTextHeading, {
        as: 'h4',
        size: 'xs',
      }),
      [BLOCKS.HEADING_5]: toNodeRenderer(RichTextHeading, {
        as: 'h5',
        size: 'xs',
      }),
      [BLOCKS.HEADING_6]: toNodeRenderer(RichTextHeading, {
        as: 'h6',
        size: 'xs',
      }),
      [BLOCKS.PARAGRAPH]: toNodeRenderer(RichTextParagraph),
      [BLOCKS.HR]: () => <Divider />,
      [BLOCKS.UL_LIST]: toNodeRenderer(UnorderedList),
      [BLOCKS.OL_LIST]: toNodeRenderer(OrderedList),
      [BLOCKS.QUOTE]: toNodeRenderer(RichTextQuote),
      [INLINES.HYPERLINK]: toNodeRenderer(RichTextLink),
    },
  });

export interface RichTextProps {
  content?: RichTextDataWithAsset;
  variant?: RichTextVariants;
}

export const RichText = ({ content, variant }: RichTextProps) => {
  const styles = useStyleConfig('RichText', { variant });
  return <Box __css={styles}>{content && renderAppRichText(content)}</Box>;
};
