import { Divider, Stack, Text } from '@chakra-ui/react';

import { SharedRichTextProps } from './shared-rich-text-props.type';

export const RichTextQuote = ({ children }: SharedRichTextProps) => (
  <Stack direction="row" h={16} p={2} spacing="4">
    <Divider
      borderWidth={2}
      borderColor="brand.chelseaGem"
      orientation="vertical"
    />
    <Text fontStyle="italic">{children}</Text>
  </Stack>
);
