import { Heading, HeadingProps } from '@chakra-ui/react';

import { SharedRichTextProps } from './shared-rich-text-props.type';

interface RichTextHeadingProps
  extends SharedRichTextProps,
    Pick<HeadingProps, 'as' | 'size'> {}

export const RichTextHeading = ({
  children,
  ...passThroughProps
}: RichTextHeadingProps) => (
  <Heading fontWeight="200" {...passThroughProps}>
    {children}
  </Heading>
);
