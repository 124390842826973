import { Children, Fragment, PropsWithChildren } from 'react';
import { Link, useBreakpointValue, useStyleConfig } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { useMatch } from '@reach/router';

import * as defaultLayout from './navigation-default-layout.component';
import * as menuLayout from './navigation-menu-layout.component';

export interface NavigationItemProps {
  label: string;
  to: string;
}

export const NavigationItem = ({ label, to }: NavigationItemProps) => {
  const match = useMatch(to);

  const styles = useStyleConfig('NavigationLink', {
    variant: match ? 'active' : undefined,
  });

  return (
    <Link as={GatsbyLink} to={to} sx={styles}>
      {label}
    </Link>
  );
};

export const Navigation = ({ children }: PropsWithChildren) => {
  const layout = useBreakpointValue({
    base: menuLayout,
    lg: defaultLayout,
  });

  const NavigationContainer = layout?.ContainerLayout ?? Fragment;
  const ItemContainer = layout?.ItemLayout ?? Fragment;

  return (
    <NavigationContainer>
      {Children.map(children, (child) => (
        <ItemContainer>{child}</ItemContainer>
      ))}
    </NavigationContainer>
  );
};
