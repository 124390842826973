import { defineStyleConfig } from '@chakra-ui/react';

export type RichTextVariants = 'two-column';

export const RichText = defineStyleConfig({
  baseStyle: {
    '> *:not(:last-child)': { marginBottom: 4 },
  },
  variants: {
    'two-column': {
      columnCount: [1, 1, 2],
      columnGap: 8,
    },
  },
});
